import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "me-2" }
const _hoisted_3 = { class: "neon-avatar-border" }
const _hoisted_4 = { class: "media-body d-flex" }
const _hoisted_5 = {
  key: 1,
  class: "w-100"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "d-none d-sm-block list-inline list-inline-bullet-sm microblog-comment-list small m-0" }
const _hoisted_8 = { class: "list-inline-item" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "list-inline-item" }
const _hoisted_11 = ["aria-label"]
const _hoisted_12 = { class: "list-inline-item" }
const _hoisted_13 = {
  key: 0,
  class: "list-inline-item"
}
const _hoisted_14 = { class: "d-sm-none list-inline text-muted small m-0" }
const _hoisted_15 = { class: "list-inline-item" }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "list-inline-item" }
const _hoisted_18 = {
  href: "#",
  class: "text-muted",
  "data-bs-toggle": "dropdown"
}
const _hoisted_19 = { class: "dropdown-menu" }
const _hoisted_20 = ["title"]
const _hoisted_21 = {
  key: 2,
  class: "dropdown"
}
const _hoisted_22 = {
  class: "btn btn-xs border-0 small text-muted",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-label": "Dropdown"
}
const _hoisted_23 = { class: "dropdown-menu dropdown-menu-end" }
const _hoisted_24 = {
  key: 2,
  class: "dropdown-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_avatar = _resolveComponent("vue-avatar")!
  const _component_vue_comment_form = _resolveComponent("vue-comment-form")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_flag = _resolveComponent("vue-flag")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_vue_icon = _resolveComponent("vue-icon")!
  const _directive_profile = _resolveDirective("profile")!

  return (_openBlock(), _createElementBlock("div", {
    id: $options.anchor,
    class: _normalizeClass([{'highlight-flash': $options.highlight, 'not-read': $props.comment.is_read === false, 'border border-danger': $props.comment.deleted_at}, "media"])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("a", null, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_vue_avatar, _mergeProps($props.comment.user, {
            "is-online": $props.comment.user.is_online,
            class: "i-35"
          }), null, 16 /* FULL_PROPS */, ["is-online"])
        ])
      ])), [
        [_directive_profile, $props.comment.user.id]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.comment.is_editing)
        ? (_openBlock(), _createBlock(_component_vue_comment_form, {
            key: 0,
            microblog: $props.comment,
            ref: "form",
            class: "w-100 me-1",
            editing: "",
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit($props.comment))),
            onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit($props.comment)))
          }, null, 8 /* PROPS */, ["microblog"]))
        : _createCommentVNode("v-if", true),
      (!$props.comment.is_editing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_vue_username, {
              user: $props.comment.user
            }, null, 8 /* PROPS */, ["user"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flags, (flag) => {
              return (_openBlock(), _createBlock(_component_vue_flag, {
                key: flag.id,
                flag: flag
              }, null, 8 /* PROPS */, ["flag"]))
            }), 128 /* KEYED_FRAGMENT */)),
            _createElementVNode("div", {
              class: "comment-text neon-contains-a-color-link",
              innerHTML: $props.comment.html
            }, null, 8 /* PROPS */, _hoisted_6),
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("a", {
                  href: $props.comment.url,
                  class: "text-muted cursor-pointer"
                }, [
                  _createVNode(_component_vue_timeago, {
                    datetime: $props.comment.created_at
                  }, null, 8 /* PROPS */, ["datetime"])
                ], 8 /* PROPS */, _hoisted_9)
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("span", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkAuth(_ctx.vote, $props.comment))),
                  onMouseenterOnce: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadVoters($props.comment))),
                  "aria-label": $options.commentVoters,
                  class: _normalizeClass([{'vote-active': $props.comment.is_voted}, "text-muted cursor-pointer"]),
                  "data-balloon-pos": "up",
                  "data-balloon-break": ""
                }, _toDisplayString($options.commentLabel), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_11)
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("span", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.checkAuth($options.reply))),
                  class: "text-muted cursor-pointer"
                }, " Odpowiedz ")
              ]),
              (_ctx.isAuthorized)
                ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                    _createElementVNode("span", {
                      class: "text-muted cursor-pointer",
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.flagComment && $options.flagComment(...args)))
                    }, " Zgłoś ")
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("ul", _hoisted_14, [
              _createElementVNode("li", _hoisted_15, [
                _createElementVNode("a", {
                  href: $props.comment.url,
                  class: "text-muted"
                }, [
                  _createVNode(_component_vue_timeago, {
                    datetime: $props.comment.created_at
                  }, null, 8 /* PROPS */, ["datetime"])
                ], 8 /* PROPS */, _hoisted_16)
              ]),
              _createElementVNode("li", _hoisted_17, [
                _createElementVNode("a", _hoisted_18, [
                  _createVNode(_component_vue_icon, { name: "microblogCommentMenuEditRemove" })
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("a", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.checkAuth($options.reply))),
                    href: "javascript:",
                    class: "dropdown-item text-muted"
                  }, " Odpowiedz "),
                  _createElementVNode("a", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.checkAuth(_ctx.vote, $props.comment))),
                    title: $options.commentVoters,
                    class: _normalizeClass([{'text-primary': $props.comment.is_voted, 'text-muted': !$props.comment.is_voted}, "dropdown-item"]),
                    href: "javascript:"
                  }, _toDisplayString($options.commentLabel), 11 /* TEXT, CLASS, PROPS */, _hoisted_20)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.isAuthorized)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createElementVNode("button", _hoisted_22, [
              _createVNode(_component_vue_icon, { name: "microblogCommentMenuAnswerFlag" })
            ]),
            _createElementVNode("div", _hoisted_23, [
              ($props.comment.permissions.update)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!$props.comment.deleted_at)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("a", {
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.edit($props.comment))),
                            class: "dropdown-item btn-sm-edit",
                            href: "javascript:"
                          }, [
                            _createVNode(_component_vue_icon, { name: "microblogCommentEdit" }),
                            _cache[12] || (_cache[12] = _createTextVNode(" Edytuj "))
                          ]),
                          _createElementVNode("a", {
                            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($options.deleteItem && $options.deleteItem(...args))),
                            class: "dropdown-item btn-sm-remove",
                            href: "javascript:"
                          }, [
                            _createVNode(_component_vue_icon, { name: "microblogCommentDelete" }),
                            _cache[13] || (_cache[13] = _createTextVNode(" Usuń "))
                          ])
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($options.restoreItem && $options.restoreItem(...args))),
                          class: "dropdown-item",
                          href: "javascript:"
                        }, [
                          _createVNode(_component_vue_icon, { name: "microblogCommentRestore" }),
                          _cache[14] || (_cache[14] = _createTextVNode(" Przywróć "))
                        ])),
                    ($props.comment.user.id !== _ctx.user.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              ($props.comment.user.id !== _ctx.user.id)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.block($props.comment.user))),
                    href: "javascript:",
                    class: "dropdown-item"
                  }, [
                    _createVNode(_component_vue_icon, { name: "microblogCommentBlockAuthor" }),
                    _cache[15] || (_cache[15] = _createTextVNode(" Zablokuj użytkownika "))
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}